<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation"
      class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp"
                title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>
        
        <!-- Navigasi Atas  -->
        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="/" aria-current="page">Beranda</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a :href="downloadURL">Unduh</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="blogs">Blog</a>
            </li>
            <!-- Multi Bahasa -->
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <div class="language-selector">
                <select id="language-select" v-model="selectedLanguage"
                  placeholder="Pilih Bahasa" @change="changeLanguage">
                  <option v-for="(data, i) in lang" :key="`lang_${i}`" :value="data.lang">
                    {{ data.val }}
                  </option>
                </select>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <article>
              <div class="inside-article">
                <div class="entry-content" itemprop="text">
                  <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                    <span aria-hidden="true"
                      class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient"
                      style="background: linear-gradient(180deg, rgb(2, 247, 229) 0%, rgb(0, 0, 0) 99%)" />
                    <div class="wp-block-cover__inner-container">
                      <!-- Logo Gambar Utama -->
                      <div class="wp-block-image ic sdw">
                        <figure class="aligncenter size-full">
                          <img decoding="async" width="512" height="512" src="../assets/logo.webp"
                            alt="GB WhatsApp APK" class="wp-image-615">
                        </figure>
                      </div>

                      <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                        GBWhatsApp
                      </h1>

                      <div class="code-block code-block-4"
                        style="margin: 8px auto; text-align: center; display: block; clear: both">
                        <p class="has-text-align-center v">
                          Versi: <strong>18.10</strong> | Ukuran: <strong>77MB</strong>
                        </p>
                      </div>

                      <p class="has-text-align-center d">
                        Aplikasi GBWhatsApp adalah versi modifikasi dari WhatsApp resmi. Perbedaan
                         antara GB WhatsApp dan WhatsApp terletak pada fitur kustom. Aplikasi GB WhatsApp 
                         menyediakan lebih banyak fitur kustom yang menarik jutaan pengguna. Fitur-fitur menarik 
                         tersebut termasuk menyembunyikan centang ganda, menyimpan status, mode DND, pesan tidak terbaca, 
                         anti-revoke pesan, tema dan font tidak terbatas, dan lainnya. 
                      </p>

                      <div
                        class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                        <div class="wp-block-button">
                          <a class="wp-block-button__link wp-element-button"
                            @click="downloadClick">Unduh GBWhatsApp Baru
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="beesads" />

                  <!-- Daftar Isi -->
                  <div id="toc_container" class="no_bullets">
                    <section class="table-content" v-show="!tableHide">
                      <div class="table-content-title" @click="tableHide = !tableHide">
                        Daftar Isi
                        <img src="../assets/minus.svg" alt="minus">
                      </div>

                      <ul class="toc_list">
                        <li>
                          <a href="#TBC_0"><span class="toc_number toc_depth_1">1.</span> GB WhatsApp Pro V18.10 dirilis!</a>
                        </li>
                        <li>
                          <a href="#TBC_1"><span class="toc_number toc_depth_1">2.</span> Apa itu GB WhatsApp dan GBWhatsApp Pro?</a>
                        </li>
                        <li>
                          <a href="#TBC_2"><span class="toc_number toc_depth_2">3.</span> Mengapa orang lebih suka menggunakan GB WhatsApp？</a>
                        </li>
                        <li>
                          <a href="#TBC_3"><span class="toc_number toc_depth_3">4.</span> Apa perbedaan antara GB WhatsApp pro dan WhatsApp?</a>
                        </li>
                        <li>
                          <a href="#TBC_4"><span class="toc_number toc_depth_4">5.</span> Fitur Menarik GB WhatsApp</a>
                        </li>
                        <li>
                          <a href="#TBC_5"><span class="toc_number toc_depth_5">6.</span> Unduh APK GB WhatsApp Versi Terbaru 18.10</a>
                        </li>
                        <li>
                          <a href="#TBC_6"><span class="toc_number toc_depth_6">7.</span>
                            Tangkapan Layar GB WhatsApp</a>
                        </li>
                        <li>
                          <a href="#TBC_7"><span class="toc_number toc_depth_7">8.</span> Pembaruan GB WhatsApp</a>
                        </li>
                        <li>
                          <a href="#TBC_8"><span class="toc_number toc_depth_8">9.</span> Alternatif GB WhatsApp</a>
                        </li>
                        <li>
                          <a href="#TBC_9"><span class="toc_number toc_depth_9">10.</span> Apakah GBWhatsApp Aman?</a>
                        </li>
                        <li>
                          <a href="#TBC_10"><span class="toc_number toc_depth_10">11.</span> FAQ Tentang GBWhatsApp Pro</a>
                        </li>
                        <li>
                          <a href="#TBC_11"><span class="toc_number toc_depth_11">12.</span>
                            Bagaimana cara memata-matai WhatsApp orang lain tanpa menyentuh ponsel mereka?</a>
                        </li>
                        <li>
                          <a href="#TBC_12"><span class="toc_number toc_depth_12">13.</span> Kesimpulan</a>
                        </li>
                      </ul>
                      
                    </section>
                    <section class="table-content hide" v-show="tableHide">
                      <div class="table-content-title" @click="tableHide = !tableHide">
                        Daftar Isi
                        <img src="../assets/plus.svg" alt="plus">
                      </div>
                    </section>
                    
                  </div>

                  <h2 class="h">
                    <span id="TBC_0"> GB WhatsApp Pro V18.10 dirilis!
                    </span>
                  </h2>
                  <h3>❗❗GB WhatsApp 18.10 dirilis❗❗ </h3>
                  <p>
                    Pengguna dapat memperbarui versi baru ini sekarang! Apa yang baru di versi 
                    ini? Seperti pembaruan sebelumnya, versi terbaru GB WhatsApp juga memperbarui sistem 
                    perlindungan anti-banned dan menambahkan pengantar untuk membantu pengguna memperbaiki masalah 
                    banned akun. Pengguna dapat memperbarui versi lama GB WhatsApp mereka sekarang. 
                  </p>

                  <h2 class="h">
                    <span id="TBC_1">Apa itu GB WhatsApp dan GBWhatsApp Pro? </span>
                  </h2>

                  <p>
                    Anda mungkin sudah akrab dengan WhatsApp, tetapi apakah Anda pernah mendengar tentang 
                    GB WhatsApp? Ini adalah versi yang ditingkatkan dari WhatsApp standar.
                     Ada juga WhatsApp Pro. Kedua aplikasi ini adalah aplikasi modifikasi. 
                     Mereka menyediakan fitur premium berdasarkan WhatsApp resmi. 
                     Meskipun beberapa situs web memperkenalkan kedua aplikasi ini secara terpisah, kenyataannya,
                      mereka berbagi APK yang sama dengan sedikit perbedaan fitur. Tidak ada perbedaan besar
                       antara APK GBWhatsApp dan APK GB WhatsApp Pro. Jadi, Anda bisa 
                       memilih untuk mengunduh salah satu berdasarkan preferensi Anda.
                  </p>

                  <h2 class="h">
                    <span id="TBC_2">Mengapa orang lebih suka menggunakan GB WhatsApp？ </span>
                  </h2>

                  <p>
                    Ada sedikit perbedaan antara WhatsApp asli, GB WhatsApp, 
                    GB WhatsApp Pro, dan <a class="jump-url" href="https://fmmodapk.com/">FM WhatsApp</a> untuk mengirim pesan. 
                    Namun, WhatsApp adalah resmi dan tersedia untuk banyak platform. 
                    Yang lainnya hanya tersedia untuk sistem Android. Jadi, mengapa orang lebih suka menggunakan 
                    APK GB WhatsApp Pro? Sebenarnya, GB WhatsApp Pro terus diperbarui selama 
                    bertahun-tahun. Dan, ia menambahkan fitur kustom tambahan dan lebih banyak opsi privasi.
                  </p>

                  <div
                    class="is-layout-flex wp-container-5 wp-block-columns are-vertically-aligned-center">
                    <div class="is-layout-flow wp-block-column is-vertically-aligned-center">
                      <p class="fet">
                        <i class="fa fa-check" /> Centang Biru
                      </p>

                      <p class="fet">
                        <i class="fa fa-check-double" /> Centang Ganda
                      </p>

                      <p class="fet">
                        <i class="fa fa-dot-circle" /> Sedang Online
                      </p>

                      <p class="fet">
                        <i class="fa fa-keyboard" /> Status Mengetik
                      </p>

                      <p class="fet">
                        <i class="fa fa-stop-circle" /> Status Merekam
                      </p>
                    </div>

                    <div class="is-layout-flow wp-block-column is-vertically-aligned-center">
                      <p class="fet">
                        <i class="fa fa-microphone" /> Pengaturan Mikrofon
                      </p>

                      <p class="fet">
                        <i class="fa fa-history" /> Menjadwalkan Pesan
                      </p>

                      <p class="fet">
                        <i class="fa fa-reply-all" /> Balas Otomatis
                      </p>

                      <p class="fet">
                        <i class="fa fa-cloud-download-alt" /> Simpan Status
                      </p>

                      <p class="fet">
                        <i class="fa fa-plane" /> Mode DND
                      </p>
                    </div>

                    <div class="is-layout-flow wp-block-column">
                      <p class="fet">
                        <i class="fa fa-ban" /> Layanan Anti-banned
                      </p>

                      <p class="fet">
                        <i class="fa fa-fingerprint" /> Kunci Sidik Jari
                      </p>

                      <p class="fet">
                        <i class="fa fa-cogs" /> Tema &amp; Font
                      </p>

                      <p class="fet">
                        <i class="fa fa-user-circle" /> Ubah Ikon
                      </p>

                      <p class="fet">
                        <i class="fa fa-phone-alt" /> Nonaktifkan Panggilan
                      </p>
                    </div>
                  </div>

                  <h2 class="h">
                    <span id="TBC_3"> Apa perbedaan antara GB WhatsApp pro dan WhatsApp?
                    </span>
                  </h2>
                  <p>
                    Setelah pengenalan singkat tentang GB WhatsApp, berikut adalah perbandingan jelas 
                    antara GB WhatsApp dan WhatsApp. Dengan membandingkan perbedaan fitur-fitur ini, Anda dapat memutuskan mana yang akan digunakan.
                  </p>

                  <figure class="wp-block-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Fitur</th>
                          <th>GBWhatsApp Pro</th>
                          <th>WhatsApp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Mode DND</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Mode Hantu</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Batas Pengiriman File</td>
                          <td>999MB</td>
                          <td>100MB</td>
                        </tr>
                        <tr>
                          <td>Kualitas Gambar</td>
                          <td>Asli</td>
                          <td>Kompresi</td>
                        </tr>
                        <tr>
                          <td>Tema</td>
                          <td>4000+</td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>Balas Otomatis</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Pesan Tidak Terbaca</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Pesan Massal</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Ubah Font</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Anti-Revoke</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Sembunyikan Centang Ganda</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Ubah Centang Biru</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Kunci Keamanan</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Ubah Ikon</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                      </tbody>
                    </table>
                  </figure>

                  <!-- Fitur -->
                  <h2 class="h">
                    <span id="TBC_4"> Fitur Menarik GB WhatsApp </span>
                  </h2>

                  <div class="is-layout-flex wp-container-8 wp-block-columns">
                    <div class="is-layout-flow wp-block-column">
                      <h3 class="h3">Terjemahan</h3>
                      <p>
                        GB WhatsApp Pro sekarang memungkinkan pengguna untuk menerjemahkan lebih banyak pesan di dalam obrolan, membuat komunikasi lebih mudah diakses antar bahasa.
                      </p>

                      <h3 class="h3">Sembunyikan Ikon Profil</h3>
                      <p>
                        Di versi terbaru GB WhatsApp Pro, Anda dapat menyembunyikan ikon profil Anda dari orang lain. Fitur ini sekarang tersedia untuk privasi tambahan.
                      </p>

                      <h3 class="h3">Pergi ke Pesan Pertama</h3>
                      <p>
                        Perlu mengunjungi pesan penting dari awal obrolan? Dengan fitur ini, Anda tidak perlu lagi menggulir secara manual. Cukup klik opsi, dan Anda akan melompat langsung ke pesan pertama dalam hitungan detik.
                      </p>

                      <h3 class="h3">Tema</h3>
                      <p>
                        GB WhatsApp Pro menawarkan berbagai tema, memungkinkan Anda untuk mengubah tata letak aplikasi kapan saja Anda mau. Dengan lebih dari 4000+ tema yang tersedia, ini adalah salah satu alasan utama mengapa pengguna memilih GB WhatsApp.
                      </p>

                      <h3 class="h3">Mode DND</h3>
                      <p>
                        Mode Do Not Disturb yang ada dalam GB WhatsApp Pro memungkinkan Anda untuk menonaktifkan koneksi internet hanya untuk aplikasi ini, sehingga Anda tidak akan terganggu oleh pesan WhatsApp saat menggunakan aplikasi lain di ponsel Anda.
                      </p>

                      <h3 class="h3">Kunci Aplikasi</h3>
                      <p>
                        GB WhatsApp Pro menyertakan fitur kunci aplikasi, memungkinkan Anda untuk mengamankan pesan Anda dengan meminta pola, PIN, kata sandi, atau sidik jari untuk mengakses aplikasi. Ini memastikan obrolan Anda tetap pribadi, bahkan jika orang lain dapat mengakses ponsel Anda.
                      </p>
                    </div>

                    <div class="is-layout-flow wp-block-column">
                      <h3 class="h3">Balas Otomatis</h3>
                      <p>
                        Atur balasan otomatis untuk pesan ketika Anda tidak dapat merespons segera. Anda dapat menggunakan fitur ini untuk tujuan bisnis tanpa perlu mengunduh APK GB WhatsApp Business.
                      </p>

                      <h3 class="h3">Pengirim Massal</h3>
                      <p>
                        Kirim pesan tanpa batas ke banyak kontak sekaligus dengan fitur Pengirim Massal, memudahkan untuk membagikan informasi kepada semua kontak Anda hanya dengan beberapa klik.
                      </p>

                      <h3 class="h3">Sembunyikan Status yang Dilihat</h3>
                      <p>
                        Dengan satu klik, Anda dapat melihat dan mengunduh status kontak Anda tanpa memberi tahu mereka bahwa Anda telah melihatnya, memberikan Anda lebih banyak kontrol atas privasi Anda.
                      </p>

                      <h3 class="h3">Anti-Revoke</h3>
                      <p>
                        Fitur anti-revoke di GB WhatsApp Pro memastikan bahwa bahkan jika seseorang menghapus pesan setelah mengirimnya, Anda masih dapat melihatnya secara default.
                      </p>

                      <h3 class="h3">Kirim Gambar Maksimal</h3>
                      <p>
                        GB WhatsApp Pro memungkinkan Anda mengirim lebih dari 100 gambar sekaligus, melampaui batas resmi WhatsApp. Anda dapat dengan mudah membagikan banyak foto tanpa kesulitan.
                      </p>

                      <h3 class="h3">Kirim Gambar Asli</h3>
                      <p>
                        Dengan GB WhatsApp Pro, Anda dapat mengirim gambar dalam kualitas aslinya, memastikan bahwa foto Anda tetap tajam dan jelas setelah dikirim.
                      </p>

                      <h3 class="h3">Unduh Status</h3>
                      <p>
                        Simpan gambar dan video dari status yang diunggah oleh kontak Anda langsung di dalam GB WhatsApp Pro. Tidak perlu aplikasi pihak ketiga, dan fitur ini tersedia secara gratis.
                      </p>
                    </div>
                  </div>

                  <h2 class="h">
                    <span id="TBC_5"> Unduh APK GB WhatsApp Versi Terbaru 18.10 </span>
                  </h2>
                  <div class="wp-block-image ic">
                    <figure class="aligncenter size-full">
                      <img decoding="async" width="512" height="512" src="../assets/logo.webp"
                        alt="GBWhatsApp Pro" class="wp-image-615">
                    </figure>
                  </div>
                  <p>
                    GBWA Pro terus memperbarui file apk untuk pengguna Android. Baru-baru ini, ia memperbarui versi baru
                     18.10 dan menambahkan beberapa fitur baru termasuk menyembunyikan ikon profil, lebih banyak bahasa terjemahan dan 
                      pergi ke pesan pertama dalam obrolan. Jadi, apa informasi aplikasi dari GB WhatsApp? Baca tabel excel di bawah ini: 
                  </p>

                  <figure class="wp-block-table is-style-regular">
                    <table class="has-fixed-layout">
                      <tbody>
                        <tr>
                          <td>Nama</td>
                          <td>GBWhatsApp Pro</td>
                        </tr>
                        <tr>
                          <td>Versi</td>
                          <td>
                            <div class="code-block code-block-3" style="margin: 8px 0; clear: both">
                              18.10
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Ukuran</td>
                          <td>77M</td>
                        </tr>
                        <tr>
                          <td>Pengembang</td>
                          <td><a href="https://gbapksdownload.com/">AlexMODS</a></td>
                        </tr>
                        <tr>
                          <td>Diperbarui</td>
                          <td>2 Hari yang lalu</td>
                        </tr>
                      </tbody>
                    </table>
                  </figure>

                  <div
                    class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                    <div class="wp-block-button">
                      <a class="wp-block-button__link wp-element-button"
                        @click="gotodownload()">Unduh GBWhatsApp Pro</a>
                    </div>
                  </div>

                  <p>
                    <span style="font-weight: bold;">Panduan</span>: Klik tombol untuk mengunduh GB WhatsApp Pro. Ponsel Anda mungkin memberi tahu Anda bahwa file APK
                     berasal dari sumber pihak ketiga dan dapat menimbulkan risiko potensial. Untuk melanjutkan, Anda perlu 
                     mempercayai APK tersebut. Pergi ke pengaturan ponsel Anda dan aktifkan izin untuk menginstal dari sumber yang tidak dikenal.
                  </p>

                  <h2 class="h">
                    <span id="TBC_6"> Tangkapan Layar GB WhatsApp</span>
                  </h2>

                  <div class="wp-block-image">
                    <figure class="aligncenter size-full">
                      <picture>
                        <source srcset="../assets/Screenshot.webp" type="image/webp">
                        <img decoding="async" loading="lazy" width="1000" height="563"
                          src="../assets/Screenshot.png" alt="GB Tangkapan Layar" class="wp-image-48">
                      </picture>
                    </figure>
                  </div>

                  <h2 class="h">
                    <span id="TBC_7"> Pembaruan GB WhatsApp </span>
                  </h2>

                  <p>
                    Jika GB WhatsApp di ponsel Anda masih merupakan versi lama GB WhatsApp 2023, 
                    Anda harus mencopot pemasangannya dan menginstal versi baru. Ada dua metode untuk memperbarui versi baru ini.
                  </p>
                  <li>
                    Pertama, kunjungi situs web resmi unduhan APK GBWhatsApp untuk mendapatkannya. Anda juga dapat mengunduhnya di 
                    beranda situs kami. Ikuti panduan untuk menginstal aplikasi. 
                  </li>
                  <li>
                    Kedua, pergi ke pengaturan dan periksa versi saat ini dari GB WhatsApp. Jika versi saat ini 
                    adalah versi lama, Anda kemudian dapat mengetuk tombol "Pembaruan GB WhatsApp" dan menunggu untuk mengunduh. 
                  </li>

                  <h2 class="h">
                    <span id="TBC_8"> Alternatif GB WhatsApp </span>
                  </h2>
                  <p>
                    Tidak hanya ada satu aplikasi modifikasi dari WhatsApp. Jika GB WhatsApp atau GB WhatsApp Pro tidak dapat 
                    digunakan di Android Anda, Anda bisa mencoba alternatif di bawah ini:
                  </p>
                  <li>FM WhatsApp</li>
                  <li>WhatsApp Plus</li>
                  <li>OB WhatsApp</li>
                  <li>OG WhatsApp</li>
                  <li>YO WhatsApp</li>
                  <li>Aero WhatsApp</li>
                  <li>Gold WhatsApp</li>
                  <li>Blue WhatsApp</li>

                
                  <h2 class="h">
                    <span id="TBC_9"> Apakah GBWhatsApp aman? </span>
                  </h2>

                  <p>
                    Keamanan aplikasi modifikasi adalah hal pertama yang menjadi perhatian kebanyakan orang. <br>

                    Berdasarkan beberapa tahun mempromosikan GB WhatsApp, kami belum menemukan pengguna yang 
                    mengalami kehilangan informasi pribadi. Hanya sejumlah kecil orang yang mengeluh 
                    tentang kehilangan data obrolan atau masalah banned. Jadi, kami bisa mengatakan GBWhatsApp aman. <br>

                    GB WhatsApp telah berkembang hingga titik di mana banyak situs web menyediakan layanan unduhan.
                     Untuk menggunakan GB WhatsApp dengan aman, kami merekomendasikan pengguna untuk memilih situs yang dapat diandalkan dan selalu mendapatkan 
                     informasi terbaru darinya. Situs kami bisa menjadi pilihan pertama Anda, karena tidak ada risiko dalam APK GB WhatsApp yang kami sediakan. <br>

                    Jika Anda masih tidak mempercayai APK pihak ketiga, Anda bisa menggunakan nomor telepon baru untuk mendaftar dan login 
                     ke aplikasi GB WhatsApp untuk menikmati fitur-fitur tanpa batas ini. 
                  </p>


                  <h2 class="h">
                    <span id="TBC_10"> FAQ tentang GBWhatsApp Pro </span>
                  </h2>
                  <div class="schema-faq wp-block-yoast-faq-block">
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Akun saya dibanned, bagaimana cara membukanya?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Anda bisa menghubungkan GB WhatsApp sebagai perangkat pendamping untuk WhatsApp. Sebagai alternatif, cari metode di YouTube. Ada banyak solusi berguna yang tersedia.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Bagaimana saya bisa memulihkan akun GB WhatsApp saya yang dibanned?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Unduh APK GB WhatsApp yang diperbarui dan coba lagi. Versi 18.10 menambahkan panduan untuk berhasil login.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Bisakah saya menggunakan GB Pro dan WhatsApp Resmi di ponsel yang sama?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Ya, Anda dapat menggunakan keduanya di perangkat yang sama, tetapi dengan akun yang berbeda.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Bisakah saya mentransfer data WhatsApp ke GBWhatsApp?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Ya, pergi ke drive lokal di ponsel Android Anda dan temukan data WhatsApp. Salin file ke folder GB WhatsApp. Untuk detail lebih lanjut, lihat blog kami.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Apakah nomor saya akan dibanned dengan menggunakan GBWA?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Tidak, ini adalah bug lama. GBWhatsApp sekarang 100% aman, dan <a href="https://www.whatsapp.com/legal/updates/terms-of-service/?lang=en">Ketentuan Layanan</a> WhatsApp berlaku.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Bisakah saya menyembunyikan GBWhatsApp dari ponsel saya?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Anda dapat mengubah ikon GBWA, jadi tidak ada yang bisa mengetahui bahwa itu adalah WhatsApp.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Bisakah kita menggunakan GB WhatsApp untuk tujuan bisnis?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Ya, fitur balas otomatis sangat bagus untuk penggunaan bisnis, meskipun opsi Toko tidak akan tersedia.
                      </p>
                    </div>
                  </div>



                  <h2 class="h">
                    <span id="TBC_11"> Bagaimana cara memata-matai WhatsApp orang lain tanpa menyentuh ponsel mereka?
                    </span>
                  </h2>

                  <p>
                    Meskipun GB WhatsApp adalah versi modifikasi dari WhatsApp, itu dikembangkan hanya untuk beberapa fitur premium. 
                    Pengguna dapat menggunakannya untuk fitur kustom lebih banyak, tetapi fitur mata-mata tidak tersedia. 
                    Ketidakhadiran fitur mata-mata ini menekankan pentingnya privasi dan keamanan dalam aplikasi. 
                    Pengguna dapat merasa yakin bahwa percakapan dan data pribadi mereka tetap terlindungi, karena GB WhatsApp 
                    tidak memfasilitasi akses tidak sah terhadap aktivitas pengguna.
                  </p>


            
                  <h2 class="h">
                    <span id="TBC_12"> Kesimpulan </span>
                  </h2>

                  <p>
                    Bagi mereka yang mencari pengalaman WhatsApp yang lebih dipersonalisasi dan kaya fitur, GBWhatsApp Pro adalah pilihan yang sangat baik. 
                    Dengan berbagai fitur yang luas, mudah untuk melihat mengapa jutaan pengguna di seluruh dunia lebih memilihnya dibandingkan 
                    WhatsApp asli. Untuk tetap terinformasi tentang pembaruan di masa mendatang, pastikan untuk menyimpan atau menandai halaman ini .
                  </p>


                  <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
                </div>
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>

    <div id="comment-box">
      <div class="title">
        Tinggalkan Komentar
      </div>
      <input v-model="comment.name" type="text" class="nameClass" placeholder="nama Anda">
      <input v-model="comment.email" type="email" class="emailClass" placeholder="Email">
      <textarea v-model="comment.content" class="contentClass" />
      <button class="submitClass" @click="submitClick">
        Kirim
      </button>
      <div class="commentList-box">
        <div v-for="item in showCommentList" :key="item.time" class="commentClass">
          <img src="@/assets/logo.webp" alt="">
          <div class="container">
            <div class="commentBoxClass">
              <div>
                <span class="commentName">{{ item.name }}</span>
                <span class="commentTime">{{ item.time }}</span>
              </div>
              <span class="commentContent">{{ item.content }}</span>
            </div>
            <div class="triangle" />
          </div>
        </div>
      </div>
      <button class="moreClass" @click="moreClick">
        {{ showText }}
      </button>
    </div>

    <div id="snippet-box">
      <div class="snippet-title">
        Ringkasan
      </div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">
            Peringkat Penulis
          </div>
          <div class="snippet-data-img">
            <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span>
          </div>
          <div class="snippet-clear" />
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">
              Peringkat Agregat
            </div>
            <div class="snippet-data-img">
              <span itemprop="ratingValue">3.65</span> berdasarkan
              <span class="rating-count" itemprop="ratingCount">46258</span>
              suara
            </div>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Nama Perangkat Lunak
          </div>
          <div class="snippet-data-img">
            <span itemprop="name">GBWhatsApp APK</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Sistem Operasi
          </div>
          <div class="snippet-data-img">
            <span itemprop="operatingSystem">Android</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Kategori Perangkat Lunak
          </div>
          <div class="snippet-data-img">
            <span itemprop="applicationCategory">Aplikasi</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Harga
          </div>
          <div class="snippet-data-img">
            <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Halaman Arahan
          </div>
          <div class="snippet-data-img">
            <a itemprop="featureList" href="https://gbwhatapks.com/">https://gbwhatapks.com/</a>
          </div>
          <div class="snippet-clear" />
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="#">Kebijakan Privasi</a>
          </div>
          <div class="copyright-bar">
            2022 © Semua Hak Dilindungi <strong><a href="/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Unduh GBWhatsApp Baru
    </div>
  </div>
</template>



<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import { allCommentList } from '@/utlis/allCommentList.js';
export default {
  name: 'Home',
  data () {
    return {
      pageName: 'home',
      tableHide: true,
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'id',
      lang: [
        { lang: 'en', val: '🇺🇸 English' },
        { lang: 'ar', val: '🇦🇪 اَلْعَرَبِيَّةُ' },
        { lang: 'id', val: '🇮🇩 Bahasa Indonesia' },
        { lang: 'pt', val: '🇵🇹 Português' },
        { lang: 'es', val: '🇪🇸 Español' },
        { lang: 'hi', val: '🇮🇳 हिंदी' },
      ],
    };
  },
  computed: {
    allCommentList () {
      const regularTime = Date.parse('2023/11/24 00:00:00');
      let currentTime = new Date().getTime();
      let Difference = Math.floor((currentTime - regularTime) / (3 * 24 * 60 * 60 * 1000));
      return allCommentList.slice(0, 20 + Difference).sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()) || [];
    },
    downloadURL () {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/downloadpage'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/downloadpage${params}`;
      }
    },
    showText () {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        return 'Click Here To Load More Comments';
      } else {
        return 'No More Comments';
      }
    },
    showCommentList () {
      return this.allCommentList.slice(0, (this.clickNum + 1) * 5) || [];
    },
  },
  mounted () {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    moreClick () {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        this.clickNum += 1;
      }
    },
    submitClick () {
      this.$Dialog
        .confirm({
          title: 'Tip',
          message: 'Your comment has been submitted successfully and will be displayed below after review.',
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
        })
        .then(() => {
          this.comment.name = '';
          this.comment.email = '';
          this.comment.content = '';
        })
        .catch(() => {
          console.log('取消');
        });
    },
    changeLanguage (lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({ name: `${this.selectedLanguage}-gb` });
    },
    downloadClick () {
      this.$router.push({ name: 'download' });
    },
    getOfficalApk (type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatsapp.chat',
          appName: 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload () {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/downloadpage" + params;
      // return `/downloadpage${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog () {
      window.location.href = '/blogs';
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
